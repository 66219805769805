import Contact from './Contact'
import { Check2 } from 'components/Icons'
import React, { useEffect } from 'react'
import { prepareData } from 'utils/googleData'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import Lottie from 'react-lottie'

export default function ContactUsFormWrapper(props) {
    const {
        enable,
        title,
        subtitle,
        text,
        description,
        cta,
        direction,
        style,
        gatsbyImage,
        gatsbyImageSrc,
        gatsbyImage2Src,
        gatsbyImage3Src,
        features,
        gatsbyImage4,
        formUrl,
        confirmationTitle,
        confirmationMessageCTA,
        lottie,
        formFields,
    } = prepareData({ props })
    console.log('gatsbyImage', JSON.stringify(gatsbyImage))
    if (enable?.toLowerCase?.() === 'false' && !process.env.RENDER_DISABLED_COMPONENTS) {
        return <></>
    }
    const search = props.location?.search
    const [ready2Data, setReady2Data] = React.useState<any | null>(null)
    useEffect(() => {
        import('../../../static/animations/sales-ready2.json').then((data) => setReady2Data(data.default))
    }, [])
    const params = new URLSearchParams(search)
    return (
        <>
            <div className="py-4 px-5 lg:py-12">
                <section className="mx-auto grid max-w-5xl gap-y-12 md:grid-cols-2 md:gap-x-16">
                    <div className="order-2 md:order-1">
                        <h3 className="mt-1 mb-4 text-lg">{description}</h3>
                        <ul className="m-0 mt-2 grid list-none space-y-1 p-0 sm:grid-flow-col sm:grid-rows-3">
                            {features.map((feature, index) => {
                                return (
                                    <li key={index} className="flex space-x-2">
                                        <Check2 className="w-4 opacity-60" />
                                        <span>{feature?.title}</span>
                                    </li>
                                )
                            })}
                        </ul>
                        <h3 className="mt-14 mb-12 text-lg">{title}</h3>

                        <div className="grid gap-y-12 sm:grid-cols-2 sm:gap-x-12">
                            <div>
                                <div className="relative max-w-[200px] flex-shrink-0 rounded-md bg-[#2D2D2D] p-2 text-center sm:-mr-8 sm:max-w-full sm:rotate-6 sm:p-3">
                                    <p className="m-0 font-comic text-xs font-bold leading-tight text-white sm:text-base">
                                        {subtitle}
                                    </p>
                                    <svg
                                        className="absolute right-6 -bottom-5 -scale-x-1"
                                        width="35"
                                        height="29"
                                        viewBox="0 0 35 29"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M34.0329 28.7305L28.9422 2.03952L0.169405 0.617765C0.169405 0.617765 12.4378 8.50347 18.738 13.9774C25.0381 19.4513 34.0329 28.7305 34.0329 28.7305Z"
                                            fill="#2D2D2D"
                                        />
                                    </svg>
                                </div>
                                {lottie && ready2Data ? (
                                    <Lottie
                                        width={252}
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: ready2Data,
                                        }}
                                    />
                                ) : (
                                    <StaticImage
                                        width={252}
                                        src="../Pricing/images/vacation-ready.png"
                                        alt="Vacation Ready"
                                        placeholder="blurred"
                                    />
                                    //     <GatsbyImage
                                    //     image={gatsbyImage4}
                                    //     alt="Look at our Customers"
                                    //     objectFit="contain"
                                    // />
                                )}
                            </div>
                            <div className="order-first flex flex-wrap justify-center gap-4  sm:order-last md:flex-col md:justify-start md:gap-0 md:space-y-12">
                                <img src={gatsbyImageSrc} className="md:max-w-auto max-w-[150px]" />
                                <img src={gatsbyImage2Src} className="md:max-w-auto max-w-[150px]" />
                                <img src={gatsbyImage3Src} className="md:max-w-auto max-w-[150px]" />
                            </div>
                        </div>
                    </div>
                    <div className="order-1 md:order-2">
                        <h3 className="mb-1">Contact us</h3>
                        <Contact
                            initialValues={{
                                product: params.has('edition')
                                    ? params.get('edition') === 'enterprise' && 'iCloudReady Cloud Enterprise'
                                    : undefined,
                            }}
                            formUrl={formUrl}
                            confirmationTitle={confirmationTitle}
                            confirmationMessageCTA={confirmationMessageCTA}
                            formFields={formFields}
                        />
                    </div>
                </section>
            </div>
        </>
    )
}
