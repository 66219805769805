import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { prepareData } from 'utils/googleData'
import Lottie from 'react-lottie'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

export default function ContactUsHero(props) {
    const { enable, title, subtitle, text, cta, direction, style, lottie, gatsbyImage, gatsbyImage4 } = prepareData({
        props,
    })
    if (enable?.toLowerCase?.() === 'false' && !process.env.RENDER_DISABLED_COMPONENTS) {
        return <></>
    }
    if (!lottie) {
        return (
            <>
                <div className="py-4 px-5 lg:py-12">
                    <section className="mb-12">
                        <div className="text-center">
                            <GatsbyImage image={gatsbyImage} alt="Contact Us" objectFit="contain" />
                            <h1 className="mt-4 mb-2 text-3xl md:text-5xl">{title}</h1>
                            <p className="m-0">
                                {subtitle}
                                {cta?.label && cta.label !== '' && ` (`}
                                <Link to={cta?.url}>{cta?.label}</Link>
                                {cta?.label && cta.label !== '' && `)`}
                                {text}{' '}
                            </p>
                        </div>
                    </section>
                </div>
            </>
        )
    }

    const [ready1Data, setReady1Data] = React.useState<any | null>(null)

    useEffect(() => {
        import('../../../static/animations/salesreready.json').then((data) => setReady1Data(data.default))
    }, [])

    return (
        <>
            <div className="py-4 px-5 lg:py-12">
                <section className="mb-12">
                    <div className="text-center">
                        {ready1Data ? (
                            <Lottie
                                width={300}
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: ready1Data,
                                }}
                            />
                        ) : (
                            <StaticImage
                                width={300}
                                src="./images/contact-us.png"
                                alt="Contact Us"
                                placeholder="blurred"
                            />
                        )}
                        {/* <StaticImage
            loading="eager"
            placeholder="none"
            width={750}
            alt="Sales hedgereadys"
            src="./images/contact-us.png"
        /> */}
                        <h1 className="mt-4 mb-2 text-3xl md:text-5xl">{title}</h1>
                        <p className="m-0">
                            {subtitle}
                            {cta?.label && cta.label !== '' && ` (`}
                            <Link to={cta?.url}>{cta?.label}</Link>
                            {cta?.label && cta.label !== '' && `)`}
                            {text}
                        </p>
                    </div>
                </section>
            </div>
        </>
    )
}
