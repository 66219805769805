import { FormikErrors } from 'formik'
import React, { Dispatch, useEffect, InputHTMLAttributes, SetStateAction, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { button } from 'components/CallToAction'
import tracking from 'utils/analytics'
import * as Yup from 'yup'
import { useLocation } from '@reach/router'
import Link from 'components/Link'
import { animateScroll as scroll } from 'react-scroll'
import Confetti from 'react-confetti'
import { useUser } from 'hooks/useUser'
import { motion } from 'framer-motion'
import useiCloudReady from 'hooks/useiCloudReady'
import TextareaAutosize from 'react-textarea-autosize'

const inputContainerClasses = `p-4 bg-tan  dark:bg-dark group active:bg-white focus-within:bg-white relative text-left`

let fields
export interface IInputProps {
    setFieldValue: (
        field: string,
        value: string | number,
        shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<{ [k: string]: string }>>
    values: {
        [k: string]: string | undefined
    }
    name: string
    reference?: any
    options?: { value?: string; hubspotValue: string | number; label?: string }[]
    errors: FormikErrors<{
        [k: string]: string
    }>
    validateField: (name: string) => Promise<void> | Promise<string | undefined>
    openOptions: string[]
    setOpenOptions: Dispatch<SetStateAction<string[]>>
    placeholder?: string
}

export function TextArea(props: React.RefAttributes<HTMLTextAreaElement> & IInputProps) {
    const { setFieldValue, values, reference, options, errors, validateField, openOptions, setOpenOptions, ...other } =
        props
    const error = errors[props.name]
    const [height, setHeight] = useState<string | number>('auto')
    return (
        <label style={{ height }} className={`${inputContainerClasses} ${error ? 'pb-8' : ''}`} htmlFor={other.name}>
            <TextareaAutosize
                onHeightChange={(height) => setHeight(height + 32)}
                onBlur={() => validateField(props.name)}
                className={`absolute left-0 w-full bg-transparent px-4 outline-none ${
                    error ? 'bottom-6 placeholder-shown:bottom-8' : 'bottom-2 placeholder-shown:bottom-4'
                } peer resize-none border-0 py-0 shadow-none ring-0 transition-all placeholder-shown:placeholder-transparent focus:ring-0`}
                id={other.name}
                {...other}
            />
            <span className="relative -top-3 text-xs transition-all peer-placeholder-shown:top-0 peer-placeholder-shown:text-base peer-placeholder-shown:opacity-50">
                {other.placeholder}
            </span>
            {error && <p className="absolute bottom-1 m-0 text-sm font-semibold text-red">{error}</p>}
        </label>
    )
}

export function Input(props: InputHTMLAttributes<HTMLInputElement> & IInputProps) {
    const { setFieldValue, values, reference, options, errors, validateField, openOptions, setOpenOptions, ...other } =
        props
    const error = errors[props.name]
    return (
        <label className={`${inputContainerClasses} ${error ? 'pb-8' : ''}`} htmlFor={other.name}>
            <input
                onBlur={() => validateField(props.name)}
                className={`absolute left-0 w-full bg-transparent px-4 outline-none ${
                    error ? 'bottom-6 placeholder-shown:bottom-8' : 'bottom-2 placeholder-shown:bottom-4'
                } peer border-0 py-0 shadow-none ring-0 transition-all placeholder-shown:placeholder-transparent focus:ring-0`}
                id={other.name}
                {...other}
            />
            <span className="relative -top-3 text-xs transition-all peer-placeholder-shown:top-0 peer-placeholder-shown:text-base peer-placeholder-shown:opacity-50">
                {other.placeholder}
            </span>
            {error && <p className="absolute bottom-1 m-0 text-sm font-semibold text-red">{error}</p>}
        </label>
    )
}

export function Radio(props: InputHTMLAttributes<HTMLInputElement> & IInputProps & { label?: string }) {
    const {
        setFieldValue,
        values,
        reference,
        errors,
        validateField,
        options,
        label,
        openOptions,
        setOpenOptions,
        ...other
    } = props

    const handleChange = async (e: React.FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget
        other.name && value && (await setFieldValue(other.name, value))
    }

    const handleClick = () => {
        const nextIndex = fields.findIndex((field) => field.name === other.name) + 1
        const nextField = fields[nextIndex]
        if (nextField && typeof window !== 'undefined') {
            const nextName = nextField?.name
            const nextValue =
                nextField?.options && (nextField?.options[0]?.value || nextField?.options[0]?.hubspotValue)
            const nextID = `${nextName}${nextValue ? '-' + nextValue : ''}`
            !openOptions.includes(nextName) && setOpenOptions([...openOptions, nextName])
            const nextEl = document.getElementById(nextID)
            if (!values[nextName]) {
                setTimeout(() => {
                    nextEl?.focus()
                    setFieldValue(nextName, nextValue || '')
                }, 0)
            }
        }
    }

    return (
        <label
            onMouseUp={handleClick}
            className="relative w-full cursor-pointer text-center"
            htmlFor={`${other.name}-${other.value}`}
        >
            <input
                checked={values[other.name] == other.value}
                className="peer absolute inset-0 opacity-0"
                {...other}
                type="radio"
                value={props.value}
                onChange={handleChange}
                id={`${other.name}-${other.value}`}
                {...(reference ? { ref: reference } : {})}
            />
            <span className="block w-full rounded-md border-[2px] border-black/10 py-2  text-sm peer-checked:!border-black/80 peer-focus:border-black/40">
                {label || other.value}
            </span>
        </label>
    )
}

export function RadioGroup(props: InputHTMLAttributes<HTMLInputElement> & IInputProps) {
    const { options, errors, validateField, openOptions, setOpenOptions, values, setFieldValue, ...other } = props
    const error = errors[props.name]
    const open = openOptions.includes(props.name)
    const ref = useRef()
    return (
        <div
            onFocus={() => {
                !openOptions.includes(other.name) && setOpenOptions([...openOptions, other.name])
            }}
            onClick={() => {
                if (options && !openOptions.includes(other.name)) {
                    setOpenOptions([...openOptions, other.name])
                    if (!values[other.name]) {
                        ref.current?.focus()
                        setFieldValue(other.name, options[0]?.value || options[0]?.hubspotValue)
                    }
                }
            }}
            className={`${inputContainerClasses} ${error ? 'pb-8' : ''} cursor-pointer`}
        >
            <p
                className={`m-0 ${open ? 'text-sm opacity-100' : 'opacity-50'} transition-all`}
                id={`group-${props.name}`}
            >
                {props.placeholder}
            </p>
            <motion.div className="overflow-hidden" animate={{ height: open ? 'auto' : 0 }} initial={{ height: 0 }}>
                <p className="m-0 mt-1 mb-4 text-xs">
                    <strong>Tip:</strong> Use{' '}
                    <kbd
                        className="mr-1 rounded-sm border border-b-2 border-gray-accent-light/50 px-1.5 py-0.5 font-sans text-xs  dark:border-gray-accent-dark/50 dark:text-white/40"
                        style={{ fontSize: '10px' }}
                    >
                        ←
                    </kbd>
                    <kbd
                        className="rounded-sm border border-b-2 border-gray-accent-light/50 px-1.5 py-0.5 font-sans text-xs  dark:border-gray-accent-dark/50 dark:text-white/40"
                        style={{ fontSize: '10px' }}
                    >
                        →
                    </kbd>{' '}
                    to advance through options
                </p>
                <div
                    role="radiogroup"
                    aria-labelledby={`group-${props.name}`}
                    className={`mt-2 grid grid-cols-2 gap-x-2 gap-y-2 ${open ? 'opacity-100' : 'absolute opacity-0'}`}
                >
                    {options?.map((option, index) => {
                        const { value, hubspotValue, label } = option
                        return (
                            <Radio
                                reference={index === 0 && ref}
                                key={value}
                                {...props}
                                value={value || hubspotValue}
                                label={label || value}
                            />
                        )
                    })}
                </div>
            </motion.div>
            {error && <p className="absolute bottom-1 m-0 text-sm font-semibold text-red">{error}</p>}
        </div>
    )
}

// Your mapping object
const Components = {
    RadioGroup,
    Input,
    TextArea,
}
const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string(),
    workEmail: Yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
    companyName: Yup.string().required('Please enter your company name'),
    role: Yup.string().required('Please select your role'),
    monthlyActiveUsers: Yup.string().required('Please select a value'),
    // monthlyEvents: Yup.number().required('Please select a value'),
    product: Yup.string().required("Please select the product you're interested in"),
    phoneNumber: Yup.string().required('Phone number is required.'),
    personalizedDemo: Yup.string().required('Please select an option'),
    details: Yup.string().nullable(),
})

export default function Contact({
    initialValues = {},
    formUrl,
    confirmationTitle,
    confirmationMessageCTA,
    formFields,
}: {
    initialValues?: {
        [k: string]: any
    }
    formUrl?: string
    confirmationTitle?: {
        title: string
        subtitle: string
        description: string
    }
    confirmationMessageCTA?: {
        title: string
        url: string
    }
    formFields?: {
        name: string
        placeholder: string
        Component: (props: InputHTMLAttributes<HTMLInputElement> & IInputProps) => JSX.Element
        hubspotField: string
        options?: { value?: string; hubspotValue: string | number; label?: string }[]
        type?: string
    }[]
}) {
    fields = formFields
    const icloudready = useiCloudReady()
    const { href } = useLocation()
    const [submitted, setSubmitted] = useState(false)
    const [openOptions, setOpenOptions] = useState<string[]>([])
    const { user = {}, fetchUser, isLoading, getJwt } = useUser()
    const [confetti, setConfetti] = useState(true)
    const { handleSubmit, values, handleChange, setFieldValue, errors, validateField } = useFormik({
        initialValues: Object.fromEntries(formFields.map((field) => [field.name, initialValues[field.name]])),
        onSubmit: async (values) => {
            // console.log('onSubmit invoked with values:', values)
            let option
            const submission = {
                pageUri: href,
                pageName: 'Contact us',
                fields: formFields.map((field) => {
                    const value = values[field.name]
                    if (field.options?.length > 0) {
                        option = field.options?.find((option) => value === option?.value)?.hubspotValue
                    }
                    // console.log(`Field: ${field.name}, Value: ${value}, Option: ${option}`)
                    return {
                        objectTypeId: '0-1',
                        name: field.hubspotField,
                        value: option || value,
                    }
                }),
            }
            // console.log('Submission object to be sent:', submission)

            try {
                const res = await fetch(formUrl, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify(submission),
                })
                // console.log('Fetch Response:', res)
                if (res.status === 200) {
                    setSubmitted(true)
                    scroll.scrollToTop()
                    icloudready?.capture('contact_us', {
                        submission: submission,
                        user: user,
                    })
                    tracking.trackConversion('contact_us', submission, user)
                    tracking.eventTrack('interaction', 'contact_us', 'contact_sales')
                    // navigate('/contact-request-received')
                    // const res = await fetch('/.netlify/functions/contact', {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //     },
                    //     body: JSON.stringify(submission)
                    // })
                    // if (res.ok) {
                    //     console.log(`reply result is OK`)
                    // } else {
                    //     console.log(`reply result is error`)
                    // }
                } else {
                    console.log(`Received non-200 status code: ${res.status}`)
                }
            } catch (error) {
                console.error('Fetch failed:', error)
            }
        },
        validationSchema: ValidationSchema,
        validateOnChange: false,
    })
    return submitted ? (
        <>
            {confetti && (
                <div className="fixed inset-0">
                    <Confetti onConfettiComplete={() => setConfetti(false)} recycle={false} numberOfPieces={1000} />
                </div>
            )}
            <div className="mt-4 rounded-md bg-gray-accent-light px-6 py-8 dark:bg-dark">
                <h4>
                    ✅ <strong>{confirmationTitle?.title}</strong>
                </h4>
                <p>{confirmationTitle?.subtitle}</p>
                <p className="mb-0">
                    {confirmationTitle?.description}
                    <Link to={confirmationMessageCTA?.url}> {confirmationMessageCTA?.title}</Link>?
                </p>
            </div>
        </>
    ) : (
        <form onSubmit={handleSubmit}>
            <p>
                <strong>Tip:</strong> Press{' '}
                <kbd
                    role="button"
                    className="rounded-sm border border-b-2 border-gray-accent-light/50 px-1.5 py-0.5 font-sans text-xs  dark:border-gray-accent-dark/50 dark:text-white/40"
                    style={{ fontSize: '10px' }}
                >
                    TAB
                </kbd>{' '}
                to advance through the form at a breakneck pace!
            </p>
            <div className="grid divide-y divide-dashed divide-gray-accent-light border border-dashed border-gray-accent-light">
                {formFields.map(({ component, name, placeholder, type = 'text', options = [] }, index) => {
                    const Component = Components[component]

                    return (
                        <Component
                            autoFocus={index === 0}
                            key={name}
                            onChange={handleChange}
                            value={values[name]}
                            name={name}
                            placeholder={placeholder}
                            setFieldValue={setFieldValue}
                            values={values}
                            type={type}
                            options={options}
                            errors={errors}
                            validateField={validateField}
                            openOptions={openOptions}
                            setOpenOptions={setOpenOptions}
                        />
                    )
                })}
            </div>
            <button className={button(undefined, 'full', 'mt-4', 'sm')} type="submit">
                Send message
            </button>
        </form>
    )
}
