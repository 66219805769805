import Layout from 'components/Layout'
import SEO from 'components/seo'
import React, { useEffect } from 'react'
import { shortcodes } from '../../mdxGlobalComponents'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import ContactUsHero from './ContactUsHero'
import ContactUsFormWrapper from './ContactUsFormWrapper'
import { getSeoImage } from 'utils/googleData'

export default function ContactUs(pageProps) {
    const { data, location, pageContext, params, children } = pageProps
    const { pageData, locales, documentation, tutorials, customers } = data
    const {
        body,
        excerpt,
        frontmatter,
        parent,
        fields: { slug },
    } = pageData?.nodes[0]
    const {
        title,
        subtitle,
        description,
        sidebar,
        pageStyle,
        darkMode,
        hideSurvey,
        enableStarUsBanner,
        faqs,
        enableStarUsBanner2,
        enableConversionInitiator,
        seoTitle,
        seoDescription,
        seoimage,
    } = frontmatter

    const components = {
        ...shortcodes(pageProps),
        ContactUsHero: (props: any) => <ContactUsHero {...props} googleImages={[parent?.images, parent?.images2]} />,
        ContactUsFormWrapper: (props: any) => (
            <ContactUsFormWrapper {...props} googleImages={[parent?.images, parent?.images2]} location={location} />
        ),
    }

    return (
        <Layout>
            <SEO
                title={seoTitle || 'Contact Us'}
                image={getSeoImage({ googleImages: [parent?.images, parent?.images2] }) || `/images/home.png`}
            />
            <MDXProvider components={components}>
                <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
        </Layout>
    )
}
