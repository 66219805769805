import ContactUs from 'components/ContactUs'
import { graphql } from 'gatsby'

export default ContactUs
export const query = graphql`
    query getContactUsPageQuery($language: String!) {
        pageData: allMdx(filter: { fields: { slug: { eq: "/contact-us" } } }) {
            nodes {
                body
                id
                internal {
                    contentFilePath
                }
                headings {
                    depth
                    value
                }
                fields {
                    slug
                }
                frontmatter {
                    title
                    subtitle
                    description
                    showCTA
                    seoTitle
                    seoDescription
                    seoimage
                }
                parent {
                    ... on GoogleDocs {
                        id
                        images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        images2: images {
                            name
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 265, height: 265)
                            }
                        }
                    }
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language }, ns: { in: ["book-a-meeting", "common"] } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
